

export const useAIAnalyze = () => {




  const analyzeData = (tmpstr, name) => {


    let tmp = JSON.parse(tmpstr)

    // Нормативы в процентах для типов задач
    const NORMS = {
      "Коммуникация": 20, // 20% от общего времени
      "Разработка": 60, // 60% от общего времени
      "Развлечения": 10, // 10% от общего времени
      "Без категории": 10 // 10% от общего времени
    }

    // Функция для анализа работы сотрудника
    function analyzeWork(data) {
      let analysis = {
        "Разработка": 0,
        "Коммуникация": 0,
        "Развлечения": 0,
        "Без категории": 0,
        "Общее время": 0
      }

      let developmentCategories = new Set() // Хранение категорий разработки

      // Проход по каждому дню
      data.forEach(day => {
        for (let key in day) {
          if (key !== "date") {
            let activity = day[key]
            analysis[activity.type] += activity.time
            analysis["Общее время"] += activity.time

            // Если тип задачи "Разработка", сохраняем категории разработки
            if (activity.type === "Разработка") {
              if (key.toLowerCase().includes("python")) {
                developmentCategories.add("Языки программирования (Python)")
              } else if (key.toLowerCase().includes("js") || key.toLowerCase().includes("javascript")) {
                developmentCategories.add("Языки программирования (JavaScript)")
              } else if (key.toLowerCase().includes("php")) {
                developmentCategories.add("Языки программирования (PHP)")
              } else if (key.toLowerCase().includes("git")) {
                developmentCategories.add("Управление версиями (Git)")
              } else if (key.toLowerCase().includes("figma") || key.toLowerCase().includes("photoshop") || key.toLowerCase().includes("axure")) {
                developmentCategories.add("Дизайн и UX/UI")
              } else if (key.toLowerCase().includes("tailwind") || key.toLowerCase().includes("ant design")) {
                developmentCategories.add("Фреймворки и библиотеки CSS")
              } else {
                developmentCategories.add("Общие навыки разработки")
              }
            }
          }
        }
      })

      analysis.developmentCategories = Array.from(developmentCategories)
      return analysis
    }

    // Функция для генерации рекомендаций
    function generateRecommendations(analysis) {
      let recommendationsForType = []
      let recommendationsForLearn = []

      // Рассчитываем фактические проценты
      let actualPercentages = {
        "Коммуникация": (analysis["Коммуникация"] / analysis["Общее время"]) * 100,
        "Разработка": (analysis["Разработка"] / analysis["Общее время"]) * 100,
        "Развлечения": (analysis["Развлечения"] / analysis["Общее время"]) * 100,
        "Без категории": (analysis["Без категории"] / analysis["Общее время"]) * 100
      }

      // Сравниваем с нормативами и добавляем рекомендации
      for (let type in NORMS) {
        if (actualPercentages[type] > NORMS[type]) {
          let excess = actualPercentages[type] - NORMS[type]
          recommendationsForType.push({
            type: type,
            percentage: excess.toFixed(2),
            name,
            // message: `${type} сотрудника  занимает на % больше времени, чем у остальных членов команды.`
          })
        }
      }

      // Определяем наиболее важную рекомендацию по обучению
      let learningRecommendations = []
      if (analysis.developmentCategories.length > 0) {
        // Подсчитываем количество упоминаний каждой категории
        let categoryCounts = {}
        analysis.developmentCategories.forEach(category => {
          categoryCounts[category] = (categoryCounts[category] || 0) + 1
        })

        // Находим категорию с максимальным количеством упоминаний
        let maxCategory = Object.keys(categoryCounts).reduce((a, b) => categoryCounts[a] > categoryCounts[b] ? a : b)

        learningRecommendations.push(`Рекомендация по обучению: ${maxCategory}`)
      } else {
        learningRecommendations.push("Рекомендации по обучению: Улучшение коммуникативных навыков")
      }

      // Добавляем наиболее важную рекомендацию по обучению
      if (learningRecommendations.length > 0) {
        recommendationsForLearn.push({
          type: "Обучение",
          message: learningRecommendations[0]
        })
      }

      return { recommendationsForType, recommendationsForLearn: recommendationsForLearn.length > 0 ? recommendationsForLearn : ["Работа сотрудника " + name + " оптимизирована."] }
    }

    // Пример данных для анализа


    // Выполняем анализ и генерируем рекомендации
    let analysis = analyzeWork(tmp)
    let recommendations = generateRecommendations(analysis)

    console.log("Анализ работы:", analysis)
    console.log("Рекомендации по оптимизации:", recommendations)


    return { recommendations }



  }





  return { analyzeData }
}