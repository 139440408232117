import * as React from "react"
import Header from '../components/Header'

const HScreenLayout = (props) => {
  return (
    <div className="h-screen w-full">
      <div className="flex h-screen">
        <div className="flex-1 flex flex-col w-full">
          <Header  />
          <div className="flex-1 w-full h-full">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HScreenLayout