

export const useAIAnalyzeMRS = () => {




  const analyzeMRS = (issues) => {





    function generateOverallComment(issues) {
      // Разделяем входную строку на отдельные замечания
      const lines = issues.split('\n').filter(line => line.trim() !== '');
    
      // Флаги для проверки наличия разных типов проблем
      let hasIdentifierIssue = false;
      let hasAssignmentIssue = false;
      let hasMissingSemicolon = false;
      let hasUnclosedString = false;
      let hasOperatorIssue = false;
      let hasInvalidLoop = false;
      let hasVersionIssue = false;
      let hasMismatchIssue = false;
    
      // Обрабатываем каждое замечание
      lines.forEach(line => {
        if (line.includes('Expected an identifier and instead saw')) {
          hasIdentifierIssue = true;
        }
        if (line.includes('Expected an assignment or function call and instead saw')) {
          hasAssignmentIssue = true;
        }
        if (line.includes('Missing semicolon')) {
          hasMissingSemicolon = true;
        }
        if (line.includes('Unclosed string')) {
          hasUnclosedString = true;
        }
        if (line.includes('Expected an operator and instead saw')) {
          hasOperatorIssue = true;
        }
        if (line.includes('Invalid for-{a} loop left-hand-side')) {
          hasInvalidLoop = true;
        }
        if (line.includes('is available in ES')) {
          hasVersionIssue = true;
        }
        if (line.includes('Expected') && line.includes('to match')) {
          hasMismatchIssue = true;
        }
      });
    
      // Формируем обобщённый комментарий
      let comment = 'Рекомендации по исправлению проблем:';
    
      if (hasIdentifierIssue) {
        comment += ' Убедитесь, что идентификаторы (переменные, функции) используются правильно, и нет неожиданных символов.';
      }
      if (hasAssignmentIssue) {
        comment += ' Проверьте, что все выражения корректно завершаются операторами присваивания или вызовами функций.';
      }
      if (hasMissingSemicolon) {
        comment += ' Добавьте пропущенные точки с запятой в конце строк, где это необходимо.';
      }
      if (hasUnclosedString) {
        comment += ' Убедитесь, что все строки корректно закрыты кавычками.';
      }
      if (hasOperatorIssue) {
        comment += ' Проверьте, что все операторы используются правильно и нет неожиданных символов.';
      }
      if (hasInvalidLoop) {
        comment += ' Исправьте синтаксис циклов, чтобы они соответствовали правильному формату.';
      }
      if (hasVersionIssue) {
        comment += ' Проверьте, что используемые конструкции соответствуют установленной версии JavaScript.';
      }
      if (hasMismatchIssue) {
        comment += ' Проверьте соответствие открывающих и закрывающих скобок, а также других парных символов.';
      }
    
      return comment || 'Неизвестные ошибки.';
    }
    
    
    
    return generateOverallComment(issues);
    


    

  }





  return { analyzeMRS }
}