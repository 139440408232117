




import { useHttp } from './http.hook'
import { JSHINT as jshint } from 'jshint';



export const useGit = () => {
    const { gitRequest, gitTextRequest } = useHttp()


    // получаем саписок мерджреквестов нам нужны айди проекта и мержа

    const listUsersMR = async (token) => {



        let prList = []

        try {
            const req = await gitRequest(`/api/v4/projects?owned=true&membership=false`, 'GET', null, { authorization: 'Bearer ' + token })

            prList = req

        } catch (error) {
            console.log("listUsersMR error: ", error)
            return "Unauthorized"
        }




        // try {
        //     const mrList = await gitRequest(`/api/v4/events?target_type=merge_request`, 'GET', null, { authorization: 'Bearer ' + token })
        //     // return mrList
        //     //  console.log(mrList)
        //     // console.log("Список мердж реквестов-", mrList)
        //     console.log(mrList)
        //     return listDiffsOfMR(mrList, token)
        // } catch (error) {
        //     console.log("listUsersMR error: ", error)
        //     return "Unauthorized"
        // }

        let mrListTotal = []

        for (let i = 0; i < prList.length; i++) {

            try {
                const mrList = await gitRequest(`/api/v4/projects/${prList[i].id}/merge_requests?state=opened`, 'GET', null, { authorization: 'Bearer ' + token })
                // return mrList
                //  console.log(mrList)
                // console.log("Список мердж реквестов-", mrList)

                // console.log("mrList", mrList)


                mrListTotal = [...mrListTotal, ...mrList]
            } catch (error) {
                console.log("listUsersMR error: ", error)
                return "Unauthorized"
            }

        }

        console.log("mrListTotal", mrListTotal)

        return listDiffsOfMR(mrListTotal, token)

    }


    const getUsersMrCount = async (token, userName) => {

        console.log("userName", userName)

        let userId
        //?created_after=2.weeks.ago
        try {
            const user = await gitRequest(`/api/v4/merge_requests?author_username=${userName}`, 'GET', null, { authorization: 'Bearer ' + token })
            userId = user[0].id
            console.log("user.length--", user.length)

            let merged = 0
            for (let i = 0; i < user.length; i++) {
                if (user[i].state === "merged") {
                    merged++
                }
            }

            let rank = ""

            if (merged / user.length >= 0.3) {
                rank = "Junior Developer"
            }
            if (merged / user.length >= 0.6) {
                rank = "Middle Developer"
            }
            if (merged / user.length >= 0.9) {
                rank = "Senior Developer"
            }

         

            return { totalCount: user.length, rank  }
        } catch (error) {
            console.log("mrList.length: ", error)
            return []
        }






        // try {
        //     const mrList = await gitRequest(`/api/v4/merge_requests?author_id=${userId}`, 'GET', null, { authorization: 'Bearer ' + token })
        //     // return mrList
        //     //  console.log(mrList)
        //     // console.log("Список мердж реквестов-", mrList)
        //     return mrList.length
        // } catch (error) {
        //     console.log("mrList.length: ", error)
        //     return []
        // }



        // try {
        //     const mrList = await gitRequest(`/api/v4/events?target_type=merge_request&created_after=2.weeks.ago`, 'GET', null, { authorization: 'Bearer ' + token })
        //     // return mrList
        //     //  console.log(mrList)
        //     // console.log("Список мердж реквестов-", mrList)
        //     return mrList.length
        // } catch (error) {
        //     console.log("mrList.length: ", error)
        //     return []
        // }

    }






    // получаем список изменений в мердже, нам нужны пути к файлам
    const listDiffsOfMR = async (mr, token) => {

        let arr = []
        for (let i = 0; i < mr.length; i++) {

            // console.log(mr[i].target_iid)

            // try {
            //     const mrList = await gitRequest(`/api/v4/projects/${mr[i].project_id}/merge_requests/${mr[i].target_iid}/diffs`, 'GET', null, { authorization: 'Bearer ' + token })
            //     // return mrList
            //     arr = [...arr,
            //     {
            //         mr: mr[i],
            //         mrList: mrList
            //     }
            //     ]
            // } catch (error) {
            //     console.log("listUsersMR error: ", error)
            //     // return []
            // }




            try {
                const mrList = await gitRequest(`/api/v4/projects/${mr[i].project_id}/merge_requests/${mr[i].iid}/diffs`, 'GET', null, { authorization: 'Bearer ' + token })
                // return mrList
                arr = [...arr,
                {
                    mr: mr[i],
                    mrList: mrList
                }
                ]
            } catch (error) {
                console.log("listUsersMR error: ", error)
                // return []
            }



        }


        let mergeData = []



        for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < arr[i].mrList.length; j++) {

                mergeData = [...mergeData,
                {
                    project_id: arr[i].mr.project_id,
                    created_at: arr[i].mr.created_at,
                    avatar: arr[i].mr.author.avatar_url,
                    author: arr[i].mr.author.name,
                    path: arr[i].mrList[j].new_path,
                    branch: arr[i].mr.source_branch,
                }
                ]

            }
        }





        // console.log("object--",mergeData)
        return getCode(mergeData, token)

    }




    // получаем код файла
    const getCode = async (arr, token) => {

        let mergeWithCode = []

        for (let i = 0; i < arr.length; i++) {

            // ?ref=${branch}
            try {
                const getCode = await gitTextRequest(`/api/v4/projects/${arr[i].project_id}/repository/files/${arr[i].path.replaceAll('/', '%2F')}/raw?ref=${arr[i].branch}`, 'GET', null, { authorization: 'Bearer ' + token })

                mergeWithCode.push({
                    project_id: await getProjectName(arr[i].project_id, token),
                    created_at: arr[i].created_at,
                    avatar: arr[i].avatar,
                    author: arr[i].author,
                    path: arr[i].path,
                    code: getCode
                }
                )


            } catch (error) {
                console.log("getCode error: ", error)

            }


            // console.log(mergeWithCode)


        }
        //  console.log("RESULT", mergeWithCode)


        // let tmp = [
        //     {

        //         project_id: "1",
        //         created_at: "arr[i].created_at",
        //         avatar: "arr[i].avatar",
        //         author: "arr[i].author",
        //         path: "file1",
        //         code: "let a = 1;"
        //     },


        //     {

        //         project_id: "2",
        //         created_at: "arr[i].created_at",
        //         avatar: "arr[i].avatar",
        //         author: "arr[i].author",
        //         path: "file2",
        //         code: "let b = 1?"
        //     },

        //     {

        //         project_id: "3",
        //         created_at: "arr[i].created_at",
        //         avatar: "arr[i].avatar",
        //         author: "arr[i].author",
        //         path: "file3",  
        //         code: "var c = 1!;"
        //     },



        // ]

        return (mergeWithCode)

    }



    const getProjectName = async (id, token) => {

        try {
            const project = await gitRequest(`/api/v4/projects/${id}`, 'GET', null, { authorization: 'Bearer ' + token })

            return project.name
        } catch (error) {
            console.log("listUsersMR error: ", error)
            return ""
        }

    }




    return {
        listUsersMR, listDiffsOfMR, getCode, getProjectName, getUsersMrCount
    }
}

