import React, { useState, useEffect } from 'react'
import HScreen from '../layout/hScreen'
import { useSelector, useDispatch } from 'react-redux'
import { useHttp } from '../hooks/http.hook'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../components/Button'
import { Divider, Modal } from 'antd'
import Input from '../components/Input'
import { useMessage } from '../hooks/message.hook'

import { AvatarGenerator } from 'random-avatar-generator';
import { login } from '../redux/actions/auth.actions'





export const Profile = () => {
  const generator = new AvatarGenerator();
  const dispatch = useDispatch()
  const { request } = useHttp()
  const message = useMessage()
  const auth = useSelector(state => state.auth)
  console.log("auth", auth)
  // const { loading } = useHttp()
  const history = useNavigate()
  // const [showTeam, setShowTeam] = useState(false)
  // const [showMember, setShowMember] = useState(false)
  const [whatToChange, setWhatToChange] = useState(true)
  const [name, setName] = useState(auth.user.name)
  const [email, setEmail] = useState(auth.user.email)

  const changeHandler = () => {
    setWhatToChange(!whatToChange)
  }
  const changeHandleName = event => {
    setName(event.target.value)
  }
  const changeHandleEmail = event => {
    setEmail(event.target.value)
  }

  const updateHandlerProfile = async (e) => {
    try {

      const update = await request('/api/auth/updateProfile', 'POST', { name: name, email: email, avatar: "", uId: auth.user.id }, { authorization: 'Bearer ' + auth.token })

      message(update.message)
      dispatch(login({ user: update.user, token: auth.token }))
    } catch (e) {
      console.log(e)
    }

  }

  const [oldPass, setOldPass] = useState("")
  const [newPass, setNewPass] = useState("")
  const [newPass2, setNewPass2] = useState("")


  const changeHandleOldPass = event => {
    setOldPass(event.target.value)
  }

  const changeHandleNewPass = event => {
    setNewPass(event.target.value)
  }
  const changeHandleNewPass2 = event => {
    setNewPass2(event.target.value)
  }


  const updateHandlerPassword = async (e) => {

    if (newPass === newPass2) {
      try {
        const update = await request('/api/auth/updatePassword', 'POST', { password: oldPass, newPassword: newPass, userId: auth.user.id }, { authorization: 'Bearer ' + auth.token })
        message(update.message)
        dispatch(login({ user: update, token: auth.token }))
      } catch (e) {
        console.log(e)
      }
    } else {
      message("Пароли не совпадают")
    }
  }


  return (
    <HScreen
    >
      {whatToChange ?
        <div className="container mt-[75px]">
          <div className="flex items-center flex-col gap-14">

            <div className="flex flex-col gap-6 w-[600px] justify-start ">
              {/*Title*/}

              <span className='text-3xl font-semibold'>Данные профиля</span>

              {/*photo*/}
              <div className='flex flex-col gap-2'>
                <span className='text-sm text-slate-400 items-start'>Фото профиля</span>
                <img
                  style={{
                    borderRadius: '50%',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    width: '75px',
                    height: '75px',
                    border: '1px solid grey'
                  }}
                // todo check if exists`/assets/userImg/${props.user.id}.jpg`
                 src={generator.generateRandomAvatar(email)}
                // alt=""
                // onClick={() => {
                //   inputFile.current.click()
                // }}
                // onError={({ currentTarget }) => {
                //   currentTarget.onerror = null; // prevents looping
                //   currentTarget.src = auth.user.avatar_url;
                // }}
                />

                <input
                  type="file"
                  id="avatar"
                  // ref={inputFile}
                  style={{ display: 'none' }}
                  // value={form.img}
                  // onChange={imgChangeHandler}
                  accept=".jpg"
                />

              </div>


              {/*name*/}
              <Input
                className="text-blueGray-300"
                placeholder=" "
                label="Ваше имя"
                id="name"
                name="name"
                value={name}
                autoComplete="off"
                onChange={changeHandleName}
              />

              {/*email*/}
              <Input
                className="text-blueGray-300"
                placeholder=" "
                label="Ваш email"
                id="email"
                name="email"
                value={email}
                autoComplete="off"
                onChange={changeHandleEmail}
              />

              {/*btn*/}
              <Button
                type="primary"
                size="md"
                className="w-full"
                onClick={updateHandlerProfile}
              // disabled={loading}
              >
                Сохранить
              </Button>


              <p className='text-slate-400 items-start cursor-pointer hover:text-[#000]'
                onClick={changeHandler}
              >Смена пароля</p>
            </div>
          </div>

        </div>


        :



        <div className="container mt-[75px]">
          <div className="flex items-center flex-col gap-14">

            <div className="flex flex-col gap-6 w-[600px]">
              {/*Title*/}
              <span className='text-3xl font-semibold'>Смена пароля</span>

              {/*password*/}

              <Input
                className="text-blueGray-300"
                placeholder=" "
                label="Старый пароль"
                type="password"
                id="password"
                name="password"
                autoComplete="nope"
              // value={form.password}
              // onChange={changeHandler}
              />

              {/*newPassword*/}

              <Input
                className="text-blueGray-300"
                placeholder=" "
                label="Новый пароль"
                type="newPassword"
                id="newPassword"
                name="newPassword"
                autoComplete="nope"
              // value={form.newPassword}
              // onChange={changeHandler}
              />

              {/*retypePassword*/}

              <Input
                className="text-blueGray-300"
                placeholder=" "
                label="Повторите новый пароль"
                type="retypePassword"
                id="retypePassword"
                name="retypePassword"
                autoComplete="nope"
              // value={form.retypePassword}
              // onChange={changeHandler}
              />

              {/*btn*/}


              <Button
                type="primary"
                size="md"
                className="w-full"
                onClick={updateHandlerPassword}
              // disabled={loading}
              >
                Поменять пароль
              </Button>



              <p className='text-slate-400 items-start cursor-pointer hover:text-[#000]'
                onClick={changeHandler}
              >Данные профиля</p>
            </div>

          </div>

        </div>



      }


    </HScreen>
  )
}