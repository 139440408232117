import React from 'react'
import { useState, useEffect } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { useDispatch } from 'react-redux'
import { login } from '../../redux/actions/auth.actions'
import { useLocation, useNavigate } from 'react-router-dom'
import Input from '../../components/Input'
import { Button } from '../../components/Button'
import Checkbox from '../../components/Checkbox'


export const AuthPage = ({ isLogin = true }) => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const location = useLocation()
  const message = useMessage()
  const { loading, request, error, clearError } = useHttp()
  const [form, setForm] = useState({
    email: '',
    password: '',
    password1: '',

  })

  const [confirm, setConfirm] = useState(false)


  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const registerHandler = async () => {

    if (confirm) {
      try {
        const data = await request('/api/auth/register', 'POST', { ...form })
        console.log(data.message)
        history('/user/login')
        message(data.message)

      } catch (e) { }
    } else {
      message("Не приняты условия оферты")
    }

  }

  const loginHandler = async () => {


    try {
      const data = await request('/api/auth/login', 'POST', { ...form })
      message(data.message)
      dispatch(login({ token: data.token, user: data.user }))
      location.state?.from?.pathname ? history(location.state?.from?.pathname) : history('/')
    } catch (e) { }

  }

  const onChange = (e) => {
    setConfirm(e.target.checked)
  };

  return (

    <div className="h-[100vh] w-[100vw] flex justify-center items-center">
      <div className="flex flex-col w-[473px] gap-5 px-9 py-8">
        <p className="text-2xl block font-semibold mb-2">{isLogin ? `Войти` : `Регистрация`}</p>

        {/*Form*/}


        <Input
          className="text-blueGray-300 "
          placeholder="Введите свой email"
          id="email"
          name="email"
          value={form.email}
          autoComplete="off"
          onChange={changeHandler}

        />


        <Input
          className="text-blueGray-300"
          placeholder="Введите пароль"
          type="password"
          id="password"
          name="password"
          value={form.password}
          onChange={changeHandler}
        
        />

        {!isLogin ?
          <Input
            className="text-blueGray-300"
            placeholder="Повторите пароль"
            type="password"
            id="password1"
            name="password1"
            value={form.password1}
            onChange={changeHandler}
          
          />
          :
          <></>
        }

        <div className="flex justify-between">
          <Checkbox onChange={onChange}><span className='text-sm text-slate-800 cursor-pointer'>{isLogin ? `Запомнить на 30 дней` : `Регистрируясь вы принимаете условия оферты`}</span></Checkbox>
          {isLogin ?
            <span className='text-sm text-[#D85888] cursor-pointer' onClick={() => { history('/user/forget') }}>Забыли пароль?</span>
            :
            <></>
          }
        </div>

        {/*Buttons*/}
        <div className="flex flex-col justify-between items-center gap-4">
          {isLogin ?
            <>
              <Button
                type="primary"
                size="md"
                className="w-full"
                onClick={loginHandler}
                disabled={loading}
              >
                Войти
              </Button>
              <Button
                size="md"
                color="secondary"
                className="w-full"
                onClick={() => { history('/user/register') }}
                disabled={loading}
              >
                Нет аккаунта? Зарегистрируйтесь!
              </Button>
            </>
            :
            <>
              <Button
                type="primary"
                size="md"
                className="w-full"
                onClick={registerHandler}
                disabled={loading}
              >
                Зарегистрироваться
              </Button>
              <Button
                size="md"
                color="secondary"
                className="w-full"
                onClick={() => { history('/user/login') }}
                disabled={loading}
              >
                Уже есть аккаунт? Войдите
              </Button>
            </>
          }
        </div>
      </div>
    </div>

  )
}
