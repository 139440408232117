import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { configureStore } from './redux/configureStore'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'

// redux store
const store = configureStore()

// subscribe to redux
const app = (
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>
)

// React 18
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(app);

ReactDOM.render(
  app,
  document.getElementById('root')
)
