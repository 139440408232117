import React, { useState, useEffect } from 'react'
import HScreen from '../layout/hScreen'
import { useSelector } from 'react-redux'
import { useHttp } from '../hooks/http.hook'
import { Button } from '../components/Button'
import { Divider, Modal } from 'antd'
import Input from '../components/Input'
import Team from '../components/Team'
import { TextArea } from '../components/TextArea'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useMessage } from '../hooks/message.hook'




export const Teams = () => {

  const auth = useSelector(state => state.auth)
  console.log("auth", auth)
  const message = useMessage()
  const { loading } = useHttp()
  const [showTeam, setShowTeam] = useState(false)
  const [showMember, setShowMember] = useState(false)
  const { request } = useHttp()
  const [teamsUsersData, setTeamsUsersData] = useState([])
  const [newMemberName, setNewMemberName] = useState("")
  const [modalMemberState, setModalMemberState] = useState("")
  const [teamName, setTeamName] = useState("")
  const [teamDescription, setTeamDescription] = useState("")
  const [currentWeek, setCurrentWeek] = useState("")
  const staffList = [
    {
      label: "Администратор",
      key: 'Администратор',
    },
    {
      label: "Разработчик",
      key: 'Разработчик',
    },
    {
      label: 'Гость',
      key: 'Гость',
    },
  ];
  const [dropDownStaff, setDropDownStaff] = useState(staffList[0].label)
  const [globalChanges, setGlobalChanges] = useState(0)

  useEffect(() => {
    try {
      (async () => {
        const data = await request('/api/all/getTeams', 'POST', { user: auth.user.id }, { authorization: 'Bearer ' + auth.token })
        // console.log(data)
        setTeamsUsersData(data)
      })()
    } catch (e) {
      console.log(e)
    }
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const daysUntilEndOfWeek = 7 - currentDayOfWeek;
    const endOfWeek = new Date(currentDate.getTime() + daysUntilEndOfWeek * 24 * 60 * 60 * 1000);
    const startOfWeek = new Date(endOfWeek.getTime() - 5 * 24 * 60 * 60 * 1000);
    const options = { day: 'numeric', month: 'short' };
    const startDateFormatted = startOfWeek.toLocaleDateString('ru-RU', options);
    const endDateFormatted = endOfWeek.toLocaleDateString('ru-RU', options);
    setCurrentWeek(`${startDateFormatted} - ${endDateFormatted}`)
  }, [])




  useEffect(() => {
    try {
      (async () => {
        const data = await request('/api/all/getTeams', 'POST', { user: auth.user.id }, { authorization: 'Bearer ' + auth.token })
        // console.log(data)
        setTeamsUsersData(data)
      })()
    } catch (e) {
      console.log(e)
    }
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const daysUntilEndOfWeek = 7 - currentDayOfWeek;
    const endOfWeek = new Date(currentDate.getTime() + daysUntilEndOfWeek * 24 * 60 * 60 * 1000);
    const startOfWeek = new Date(endOfWeek.getTime() - 6 * 24 * 60 * 60 * 1000);
    const options = { day: 'numeric', month: 'short' };
    const startDateFormatted = startOfWeek.toLocaleDateString('ru-RU', options);
    const endDateFormatted = endOfWeek.toLocaleDateString('ru-RU', options);
    setCurrentWeek(`${startDateFormatted} - ${endDateFormatted}`)
  }, [globalChanges])


  const handleAddMember = async () => {
    try {
      const member = await request('/api/all/addmember', 'POST', { name: newMemberName, team: modalMemberState, staff: dropDownStaff }, { authorization: 'Bearer ' + auth.token })
      setGlobalChanges(globalChanges+1)
      setShowMember(false)
      message(member.message)
    } catch (e) {
      message("Такого пользователя не существует")
    }

  }

  const showModalTeam = () => { setShowTeam(true) }


  const listOfTeams = teamsUsersData?.map((team, idx) =>
    < li key={idx}>
      <Team
        teamName={Object.keys(team)[0]}
        teamDescription={team.description}
        users={team}
        setShowMember={setShowMember}
        setModalMemberState={setModalMemberState}
        setGlobalChanges={setGlobalChanges}
        globalChanges={globalChanges}
      />
    </li>
  )

  const handleCancel = () => {
    setShowTeam(false)
    setShowMember(false)
  }

  const comNameChangeHandler = (e) => {
    setTeamName(e.target.value)
  }

  const comDeskChangeHandler = (e) => {
    setTeamDescription(e.target.value)
  }

  const handleAddTeam = async () => {
    try {
      const team = await request('/api/all/addteam', 'POST', { user: auth.user.id,  name: teamName, description: teamDescription }, { authorization: 'Bearer ' + auth.token })
      setGlobalChanges(globalChanges+1)
      setShowTeam(false)
      message(team.message)
    } catch (e) {
      console.log(e)
    }
  }

  const clickStaffDropdown = ({ key }) => {
    console.log(key)
    setDropDownStaff(key)
  }











  return (
    <HScreen>
      <div className='flex flex-row items-center justify-between px-[116px] mt-[48px]'>
        <div className='flex flex-col '>
          <p className='font-semibold text-2xl font-sans'>Мои команды</p>
          <p className='text-sm font-normal text-[#94A3B8]'>Ниже отображается список ваших команд и  участников в них</p>
        </div>
        <Button
          size="xs"
          color="secondary"
          className=" h-[42px] p-1"
          onClick={showModalTeam}
          disabled={loading}
        >
          Добавить команду
        </Button>
      </div>
      <div className='px-[116px] mt-[-10px]'>
        <Divider className=" " />
      </div>

      <div className='flex flex-row  text-[#94A3B8] italic font-normal text-[13px] mx-[116px] w-full'>
        <div className=' w-[calc(100vw/3-80px)]'>Команда</div>
        <div className='w-[calc(100vw/3-150px)] '>Имя сотрудника</div>
        <div className='w-[calc(100vw/3-30px)] flex flex-row justify-between ' >
          <div>{currentWeek}</div>
          <div>{currentWeek}</div>
          <div>{currentWeek}</div>
          <div>{currentWeek}</div>
        </div>
      </div>

      {teamsUsersData.length ?
        <div className=' h-[calc(100vh-210px)] overflow-auto scrollbar-hide md:scrollbar-default '>
          <ul>{listOfTeams}</ul>
        </div>
        :
        <div className='w-full h-[calc(100vh-300px)] flex items-center justify-center'>
          <p>У вас не создано ни одной команды</p>
        </div>
      }

      <Modal
        open={showTeam}
        title={null}
        footer={null}
        centered
        width={640}
        onCancel={handleCancel}
      >
        <div className="flex flex-col gap-4">
          {/*title*/}
          <div className='flex flex-col gap-2'>
            <p className="text-2xl block">Добавить команду</p>
            <Divider className="my-2" />
          </div>
          {/*body*/}
          <div className='flex flex-col gap-4'>
            <Input
              placeholder="Название"
              id="title"
              name="title"
              autoComplete="off"
              onChange={comNameChangeHandler}
            />
            <TextArea
              rows={4}
              placeholder="Описание"
              id="key"
              name="key"
              onChange={comDeskChangeHandler}
            />
          </div>
          <div className="w-full  flex items-center justify-center gap-4">
            <Button
              size="slim"
              className="w-1/2 mt-2"
              onClick={handleAddTeam}
            >
              Сохранить
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        open={showMember}
        title={null}
        footer={null}
        centered
        width={640}
        onCancel={handleCancel}
      >
        <div className="flex flex-col gap-4">
          {/*title*/}
          <div className='flex flex-col gap-2'>
            <p className="text-2xl block">“{modalMemberState}” - Добавить члена команды</p>
            <Divider className="my-2" />
          </div>
          {/*body*/}
          <div className='flex flex-row items-center justify-between'>
            <Input
              className="w-[400px]"
              placeholder="Email"
              id="title"
              name="title"
              autoComplete="off"
              onChange={(e) => { setNewMemberName(e.target.value) }}
            />
            <Dropdown
              menu={{
                items: staffList,
                onClick: clickStaffDropdown
              }}
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {dropDownStaff}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
          <div className="w-full  flex items-center justify-center gap-4">
            <Button
              size="slim"
              className="w-1/2 mt-2"
              onClick={handleAddMember}
            >
              Добавить
            </Button>
          </div>
        </div>
      </Modal>

    </HScreen>
  )
}