import { useLocation, useNavigate } from 'react-router-dom'

export const LineMenu = ({ items = [], ...props }) => {
  const location = useLocation()
  const history = useNavigate()

  const clickHandler = (id) => {
    props.onClick(id)
  }

  return (
    <div className='flex gap-9 w-full border-b border-b-slate-300'>
      {
        items.map(el => (
          (
            <span
              key={el.id}
              className={`cursor-pointer font-semibold ${location.pathname === el?.href || el?.active ? 'text-[#D85888] font-normal text-xl border-b border-b-[#D85888]' : 'text-[#000] font-normal text-xl'}`}
              onClick={() => {
                if (el?.href) history(el?.href)
                if (el.hasOwnProperty("active")) {

                clickHandler(el.id)

                }
              }}
            >
              {el.text}
            </span>
          )
        ))
      }
    </div>
  )
}