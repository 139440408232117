import React from 'react'
import { useState, useEffect } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { useDispatch } from 'react-redux'
// import { login } from '../../redux/actions/auth.actions'
import { useLocation, useNavigate } from 'react-router-dom'
import Input from '../../components/Input'
import { Button } from '../../components/Button'
import LoginLayout from '../../layout/loginLayout'


export const ForgetPage = () => {
  // const dispatch = useDispatch()
  // const history = useNavigate()
  // const location = useLocation()
  const message = useMessage()
  const { loading, request, error, clearError } = useHttp()
  const [form, setForm] = useState({
    email: '',
  })

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const repairHandler = async () => {
    message('Repair password')
    // try {
    //   const data = await request('/api/auth/register', 'POST', { ...form })
    //   message(data.message)
    // } catch (e) {}
  }


  return (

      <div className="h-[100vh] w-[100vw] flex justify-center items-center">
        <div className="flex flex-col w-[473px] gap-5 px-9 py-8">
          <p className="text-2xl block font-semibold mb-2">Забыли пароль</p>

          {/*Form*/}
          <Input
            className="text-blueGray-300"
            placeholder="Введите свой email"
            id="email"
            name="email"
            value={form.email}
            autoComplete="off"
            onChange={changeHandler}
          />

          {/*Buttons*/}
          <div className="flex flex-col justify-between items-center gap-4">
            <>
              <Button
                type="primary"
                size="md"
                className="w-full"
                onClick={repairHandler}
                disabled={loading}
              >
                Восстановить пароль
              </Button>
            </>
          </div>
        </div>
      </div>
   
  )
}
