import React, { useState, useEffect } from 'react'
import HScreen from '../layout/hScreen'
import { useSelector } from 'react-redux'
import { useHttp } from '../hooks/http.hook'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../components/Button'
import {
  AcademicCapIcon,
} from '@heroicons/react/outline'
import { LineMenu } from '../components/LineMenu'




export const Main = () => {
  const auth = useSelector(state => state.auth)
  const { loading } = useHttp()
  const history = useNavigate()
  const [repos, setRepos] = useState([])
  const { gitRequest } = useHttp()
  const [projects, setProjects] = useState([])
  const [selectedPr, setSelectedPr] = useState("")

  useEffect(() => {
    try {
      (async () => {
        const getUserProject = await gitRequest(`/api/v4/users/${auth.user.id}/projects`, 'GET', null, { authorization: 'Bearer ' + auth.token })
        setProjects(getUserProject.map((project, idx) => {
          return {
            name: project.name,
            id: project.id
          }
        })
        )
      })()
    } catch (e) {
      console.log(e)
    }
  }, [])

  const Project = ({ name, id }) => {
    return (
      <>
        <div className='inline-flex mt-[5px] font-normal text-base hover:text-[#94A3B8] cursor-pointer' onClick={() => {
          setSelectedPr(name)
          history(`/user/project/${id}/byproject`)
        }}>
          <AcademicCapIcon className='w-[24px]' /> <p className='ml-[7px]'>{name}</p>
        </div>
      </>
    )
  }


  const listOfProject = projects.map((project, idx) =>
    <li key={idx}><Project name={project.name} id={project.id} /></li>
  );





  return (
    <HScreen setRepos={setRepos}
    >
  
      {/* <div className="flex h-screen">

        <div className="flex w-full  bg-[#ffffff]">
          <div className="flex flex-col w-full gap-[40px]">
            <div className="flex flex-col gap-4">
            </div>
          </div>
        </div>
      </div> */}
    </HScreen>
  )
}