import React, { useState, useEffect } from 'react'
import HScreen from '../layout/hScreen'
import { useSelector, useDispatch } from 'react-redux'
import { useHttp } from '../hooks/http.hook'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../components/Button'
import { Divider, Modal } from 'antd'
import Input from '../components/Input'
import { TextArea } from '../components/TextArea'

import { LineMenu } from '../components/LineMenu'

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import lab from "../img/lab.png";
import hub from "../img/hub.png";
import { useMessage } from '../hooks/message.hook'
import { login } from '../redux/actions/auth.actions'

export const Integrations = () => {
  const auth = useSelector(state => state.auth)
  const { loading } = useHttp()
  const history = useNavigate()
  const { request } = useHttp()
  const [showTeam, setShowTeam] = useState(false)
  const [showModalAdd, setShowModalAdd] = useState(false)
  const dispatch = useDispatch()
  const message = useMessage()
  const handleCancel = () => {
    setShowModalAdd(false)
  }
  const [gitlab, setGitlab] = useState("")

  const handleAddGitlab = async () => {
    try {
      const git = await request('/api/all/addGitlab', 'POST', { user_id: auth.user.id, token: gitlab }, { authorization: 'Bearer ' + auth.token })
      message(git.message)
   
      setShowModalAdd(false)
    } catch (e) {
      console.log(e)
    }
  }


  useEffect(() => {
    try {
      (async () => {
        const data = await request('/api/all/getGitlab', 'POST', { user_id: auth.user.id }, { authorization: 'Bearer ' + auth.token })
     console.log("ffff", data)
        setGitlab(data.token)
      })()
    } catch (e) {
      console.log(e)
    }
   
  }, [])





  return (
    <HScreen
    >


      <div className="container mt-[75px]">
        <div className="flex items-center flex-col gap-14">

          <div className="flex flex-col gap-6 w-[600px] justify-start ">
            {/*Title*/}
            <span className='text-3xl font-semibold'>Интеграции</span>

            <div className='w-[400px] flex flex-col gap-4'>

              {/* <Button
                size="xl"
                color="secondary"
                className="py-1"
              // onClick={showModalMember}
              // disabled={loading}
              >
                <div className='flex flex-row gap-2 items-center'><span> Подключить / Отключить Github</span>

                  <img src={hub} className="w-[18px] h-[18px]" /> </div>


              </Button> */}

              <Button
                size="xl"
                color="secondary"
                className="py-1"
                onClick={() => { setShowModalAdd(true) }}
              // disabled={loading}
              >
                <div className='flex flex-row gap-2 items-center'><span> Подключить / Отключить Gitlab</span>

                  <img src={lab} className="w-[18px] h-[18px]" /> </div>


              </Button>
            </div>

          </div>

        </div>
      </div>





      <Modal
        open={showModalAdd}
        title={null}
        footer={null}
        centered
        width={640}
        onCancel={handleCancel}
      >
        <div className="flex flex-col gap-4">
          {/*title*/}
          <div className='flex flex-col gap-2'>
            <p className="text-2xl block">Интеграция Gitlab</p>
            <Divider className="my-2" />
          </div>
          {/*body*/}
          <div className='flex flex-row items-center justify-between'>
            <Input
              className="w-[595px]"
              placeholder="Token"
              id="token"
              name="token"
              autoComplete="off"
              value={gitlab}
              onChange={(e) => { setGitlab(e.target.value) }}
            />

          </div>
          <div className="w-full  flex items-center justify-center gap-4">
            <Button
              size="slim"
              className="w-1/2 mt-2"
              onClick={handleAddGitlab}
            >
              Подключить
            </Button>
          </div>
        </div>
      </Modal>







    </HScreen>
  )
}