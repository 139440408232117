import { Checkbox as AntCheckbox, ConfigProvider } from 'antd'
import * as React from 'react'

// import 'antd/lib/checkbox/style/index.css'

const Checkbox = ({ error = null, ...props }) => {
  return (
    <div className="flex flex-col">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#D85888',
          },
        }}
      >
      <AntCheckbox
        className="
          rounded
          text-slate-800

          hover:border-[#D85888]

          focus:border-[#D85888]
          focus:ring
          focus:ring-offset-0
          focus:ring-indigo-200
          focus:ring-opacity-50"
        {...props}
        status={error && error.text && 'error'}
      />
      </ConfigProvider>
      {error && error.text && <span className="textRed text-sm pl-8">{error.text}</span>}
    </div>
  )
}

export default Checkbox

