import { Button as AntButton, Spin, ConfigProvider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import * as React from 'react'

import { arrayOf, bool, element, node, oneOf, oneOfType, string, func } from 'prop-types'
const antLoaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const Button = ({ loading, children, icon, color, className, size, ...props }) => {

  let classNames = ''
  switch (size) {
    case 'none':
      classNames = ' '
      break;
    case 'xs':
      classNames = ' py-2 px-[116px] text-[15px] rounded-lg '
      break;
    case 'lg':
      classNames = ' py-3 px-6 fs-lg font-medium rounded-md '
      break;
    case 'xl':
      classNames = ' py-[12px]  px-[44px]  font-semibold text-lg rounded-lg '
      break;
    default: // md
      classNames = 'py-3 px-4 text-lg rounded-xl font-semibold'
      break;
  }

  switch (color) {
    
    case 'secondary':
      classNames += ` 
        bg-white
        text-[#D85888]
        border-1
        border-slate-300

        hover:border-[#D85888]
        hover:text-[#D85888]

        focus:outline-none 
        focus:text-[#D85888]
        focus:border-[#D85888]
      `
      break;


      case 'secondary-2':
        classNames += ` 
          bg-white
          text-[#bfbfbf]
          border-1
          border-slate-300
  
          hover:border-[#D85888]
          hover:text-[#D85888]
  
          focus:outline-none 
          focus:text-[#D85888]
          focus:border-[#D85888]
        `
        break;
    
    
  
   
    default: //case 'primary':
      classNames += ` 
        bg-[#D85888]
        hover:bg-[#d14177] 
        active:[#d14177]
        focus:outline-none 
        focus:bg-[#d14177] 
        
        border-1
        border-transparent
        
        hover:border-transparent
        
        text-white 
        hover:text-white 
        focus:text-white 
      `
      break;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#D85888',
         
        },
      }}
    >
      <AntButton
        key={size}
        loading={loading}
        className={`
        h-max 
        active:scale-95 
        ${classNames} 
        ${className || ''}
      `}
        {...props}
      >
        {children}
        {loading && <Spin indicator={antLoaderIcon} />}
      </AntButton>
    </ConfigProvider>
  )
}

Button.propTypes = {
  size: oneOf(['none', 'xs', 'md', 'lg', 'xl']),
  color: oneOf(['primary', 'secondary', 'accent', 'danger', 'grey', 'ghost', 'gradient']),
  loading: bool,
  disabled: bool,
  className: string,
  onClick: func,
  children: oneOfType([
    arrayOf(node), node
  ])
}

Button.defaultProps = {
  size: 'lg',
  color: 'primary',
  loading: false,
  disabled: false,
}
