import React, { useState, useEffect } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { Button } from '../Button'
import Member from '../Member'


const Team = ({ teamName, teamDescription, users, setShowMember, setModalMemberState, setDrawerState, setGlobalChanges, globalChanges }) => {
    const { loading } = useHttp()

    const showModalMember = (teamName) => {
        setShowMember(true)
        setModalMemberState(teamName)
    }

    const listOfMembers = users[teamName]?.map((member, idx) =>
        < li key={idx}>
            <Member
                name={member.memberName}
                email={member.memberEmail}
                staff={member.memberRole}
                // lastActive={member.lastActive}
                statistic={member.memberStatistic}
                setDrawerState={setDrawerState}
                setGlobalChanges={setGlobalChanges}
                globalChanges={globalChanges}

            />
        </li>
    )

    return (
        <>
            {/* w-full */}
            <div className='flex flex-row font-normal text-[13px] mx-[116px] border-b-[1px] border-b-[#E2E8F0] pb-6'>
                <div className='w-[calc(100vw/3-150px)] flex flex-col mt-8'>
                    <p className='font-semibold text-[18px]'>{teamName}</p>
                    <p className='text-[#94A3B8] font-normal text-[13px'>{teamDescription}</p>
                </div>
                <div className='border-l-[1px] flex flex-col pl-6 mt-4'>
                    {listOfMembers.length ? <ul>{listOfMembers}</ul> : <div className='w-[calc(100vw) text-[16px]'>В данной команде пока отсутствуют участники</div>}
                    <div className='w-[300px] mt-4'>
                        <Button
                            size="xs"
                            color="secondary-2"
                            className="py-1"
                            onClick={() => { showModalMember(teamName) }}
                            disabled={loading}
                        >
                            Добавить члена команды
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Team

