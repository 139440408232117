import React, { useState, useEffect } from 'react'
import HScreen from '../layout/hScreen'
import { useSelector } from 'react-redux'
import { useHttp } from '../hooks/http.hook'
import { useAIAnalyzeMRS } from '../hooks/aiAnalyzeMRS.hook'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../components/Button'
import { Divider, Modal, Spin } from 'antd'
import Input from '../components/Input'
import { TextArea } from '../components/TextArea'

import { LineMenu } from '../components/LineMenu'

import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Space } from 'antd'
import lab from "../img/lab.png"
import hub from "../img/hub.png"

import { JSHINT as jshint } from 'jshint'

import {
  LightningBoltIcon,
  XCircleIcon
} from '@heroicons/react/outline'
import moment from 'moment'


import { useGit } from '../hooks/git.hook'

export const Mrs = () => {
  moment.updateLocale('ru', { week: { dow: 1 } })
  const auth = useSelector(state => state.auth)
  const { loading } = useHttp()
  const { analyzeMRS } = useAIAnalyzeMRS()
  const history = useNavigate()
  const [showTeam, setShowTeam] = useState(false)
  const [showMember, setShowMember] = useState(false)
  const { gitRequest } = useHttp()
  const { listUsersMR, listDiffsOfMR, getCode, getProjectName } = useGit()
  const [mrList, setMrList] = useState([])
  const [errors, setErrors] = useState([])
  const { request } = useHttp()







  const [mrs, setMrs] = useState([])

  // useEffect(() => {
  //   checkMistakes("let qw = 0;")
  // }, [])

  const checkErrors = value => {

  }

  const checkMistakes = (code) => {
    if (!code) {
      return
    }
    jshint(code.split('\n'))

    console.log("err-", jshint?.data()?.errors)
    // setProblems(prev => ([...prev, jshint?.data()?.errors]))
    return jshint.data().errors ? jshint.data().errors : []




    console.warn(jshint.data())
    console.warn(jshint.data().errors)
  }







  useEffect(() => {
    try {
      (async () => {
        const gitlab = await request('/api/all/getGitlab', 'POST', { user_id: auth.user.id }, { authorization: 'Bearer ' + auth.token })
        let data = await listUsersMR(gitlab.token)
        console.log("data", mrList)
        setMrList(data)

      })()

    } catch (e) {
      console.log(e)
    }
  }, [])



  useEffect(() => {
    try {

      let tmp = []
      for (let i = 0; i < mrList.length; i++) {

        tmp.push({

          project_id: mrList[i].project_id,
          created_at: mrList[i].created_at,
          avatar: mrList[i].avatar,
          author: mrList[i].author,
          path: mrList[i].path,
          err: checkMistakes(mrList[i].code)

        }
        )

      }
      tmp = tmp.filter(el => el.err)
      setMrs(tmp)
    } catch (e) {
      console.log(e)
    }


  }, [mrList])


  useEffect(() => {
    const result = mrs.map(item => {
      // Получаем массив err из текущего элемента
      const errors = item?.err || []

      // Обрезаем массив до первых 20 элементов (или меньше, если их меньше 20)
      const first20Errors = errors.slice(0, 60)

      // Сконкатенируем значения raw с переносом строки
      return first20Errors.map(error => error.raw).join('\n')
    })

    console.log(result)
  }, [mrs])









  const Errors = ({ problem }) => {


    return (
      <>
        <div className='flex items-center px-3 py-0.5'>
          {
            problem[0]?.code !== 'E019' ?
              <LightningBoltIcon className='w-3 h-3 text-[#F4C78C] mx-2' />
              :
              <XCircleIcon className='w-3 h-3 text-[#EC847D] mx-2' />
          }
          <p className='text-gray-400'>
            {problem[0]?.reason} &nbsp; Line: {problem?.line} Character: {problem?.character} Reason: {problem?.reason}
          </p>
        </div>
      </>
    )

  }






  const MR = ({ mr }) => {







    // Получаем массив err из текущего элемента
    const errors = mr?.err || []

    // Обрезаем массив до первых 20 элементов (или меньше, если их меньше 20)
    const first20Errors = errors.slice(0, 60)

    // Сконкатенируем значения raw с переносом строки
    let result = first20Errors.map(error => error.raw).join('\n')











    const listOfErrors = mr.err?.map((err, idx) =>
      <li key={idx}>
        <Errors
          problem={err}
        />
      </li>
    )

    return (
      <>
        {/* w-full */}
        <div className='flex flex-row ml-[115px] font-normal text-[13px]   border-b-[1px] border-b-[#E2E8F0] py-3 w-full'>

          <div className='flex flex-col w-[350px] border-r-[1px] border-b-[#E2E8F0]'> <p className='text-[18px] font-semibold'>Название проекта: {mr.project_id}</p>
            <div className='flex flex-row items-start justify-start'>

              <img
                style={{
                  borderRadius: '50%',
                  cursor: 'pointer',
                  width: '54px',
                  height: '54px',
                  border: '1px solid grey'
                }}
                src={mr.avatar}
              />
              <div className='flex flex-col gap-2 ml-2  '>
                <div className='text-[18px] font-semibold' >{mr.author}</div>
                <div className='text-[#94A3B8] font-normal text-[13px]'>Cоздан: {moment(mr.created_at).format('D MMM YYYY')}</div>
              </div>
            </div>
          </div>





          <div className='ml-3'>

            <div className='w-[700px] flex flex-row gap-[4px]'>
              <span className='text-[#FF8C00] text-[16px] font-bold' >AI</span>
              <div className='text-[#94A3B8] mt-[4px]'>{analyzeMRS(result)}</div>
            </div>

            <p className='mt-[20px]'>  Ошибки в файле: {mr.path}</p>
            <ul>{listOfErrors}</ul>
          </div>

        </div>
      </>
    )

  }










  const listOfMRs = mrs?.map((mr, idx) =>
    <li key={idx}>
      <MR
        mr={mr}
      />
    </li>
  )






  return (
    <HScreen
    >



      <div className="container mt-[75px]">
        <div className="flex items-center flex-col gap-14">

          <div className="flex flex-col gap-6 w-[600px]">
            {/*Title*/}
            <span className='text-3xl font-semibold'>Запросы на слияние</span>


          </div>


        </div>

      </div>
      <div className='w-[calc(100vw-230px)]   h-[calc(100vh-160px)] mt-1 overflow-auto scrollbar-hide md:scrollbar-default '>
        <div className="w-full">
          {
            mrs.length
              ?
              <ul>{listOfMRs}</ul>
              :


              mrList == "Unauthorized" ?

                <div className='w-full h-[calc(100vh-300px)] ml-[115px] flex items-center justify-center gap-2'>

                  <p>Нет интеграции с Gitlab</p>
                </div>

                :

                <div className='w-full h-[calc(100vh-300px)] ml-[115px] flex items-center justify-center gap-2'>
                  <Spin size="large" />
                  <p>Нет запросов на слияние</p>
                </div>

          }


        </div>
      </div>
    </HScreen>
  )
}