
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHttp } from '../../hooks/http.hook'
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';


const Programm = ({ name, _type, time, drawerState, setDrawerState }) => {

    const auth = useSelector(state => state.auth)
    const { request } = useHttp()
    const [typeState, setTypeState] = useState(_type)


    const type = [
        {
            label: 'Разработка',
            key: 'Разработка',
        },
        {
            label: 'Коммуникация',
            key: 'Коммуникация',
        },
        {
            label: 'Развлечения',
            key: 'Развлечения',
        },
        {
            label: 'Без категории',
            key: 'Без категории',
        },
    ];


    const clickType = async ({ key }) => {
        console.log("_type", _type) // PUT в бд, и подтягивание изменений
        console.log("key", key)
        setTypeState(key)
        const req = await request('/api/all/changeCategory', 'POST', { email: drawerState.email, oldCategory: _type, newCategory: key, programm: name }, { authorization: 'Bearer ' + auth.token })
        // console.log("JSON.parse(req.statistic)", JSON.parse(req.statistic))
        // setDrawerState(JSON.parse(req.statistic))

        setDrawerState({ name: drawerState.name, email: drawerState.email, staff: drawerState.staff, lastActive: drawerState.lastActive, statistic: req.statistic })
    };

    function whatColor(_type) {
        switch (_type) {
            case "Разработка":
                return "#2A54B3"

            case "Коммуникация":
                return "#F9DF8F"

            case "Развлечения":
                return "#F2AF96"

            case "Без категории":
                return "#F5F5F5"
        }
    }


    let fixedTime = Math.floor(time / 3600) + " ч " + Math.floor(time % 3600 / 60) + " м"



    return (
        <>
            <div className='flex flex-row justify-between w-full text-[16px] mt-5'>
                <div className='w-3/6'>{name}</div>
                <div className='w-2/6 ml-4'>
                    {/* bg-[#F5F5F5] */}
                    <div style={{ backgroundColor: whatColor(typeState) }} className='rounded-[50px] py-1 px-2 ml-[-12px] w-fit'>
                        <Dropdown
                            menu={{
                                items: type,
                                onClick: clickType
                            }}
                            trigger={['click']}
                        >
                            <span className='cursor-pointer' >
                                <Space>
                                    {typeState}
                                    <DownOutlined className='mt-1' />
                                </Space>
                            </span>
                        </Dropdown>
                    </div>
                </div>
                <div className='w-1/6'>{fixedTime}</div>
            </div>
        </>
    )
}


export default Programm