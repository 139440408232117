import { Routes, Route, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { checkLogged } from './redux/actions/auth.actions'
import { LoaderFull } from './components/Loader/LoaderFull'
import { AuthPage } from './pages/user/AuthPage'
import { NotFound } from './pages/NotFound'
import { RequireAuth } from './hoc/RequireAuth'
import { ForgetPage } from './pages/user/ForgetPassword'
import { Main } from './pages/Main'
import { UserProfilePage } from './pages/user/UserProfilePage'
import { UserPasswordPage } from './pages/user/UserPasswordPage'
import { Teams } from './pages/Teams'

import { Profile } from './pages/Profile'

import { Integrations } from './pages/Integrations'
import { Mrs } from './pages/Mrs'



function App() {
  const dispatch = useDispatch()
  const [checking, setChecking] = useState(true)

  useEffect(() => {
    // check local storage and load to store
    dispatch(checkLogged()).then(res => {
      setChecking(false)
    })
  }, [])

  if (checking) return <LoaderFull />

  return (
    <>
      <ToastContainer hideProgressBar />

      <Routes>
        <Route path="/user/register" element={<AuthPage isLogin={false} />} />
        <Route path="/user/login" element={<AuthPage />} />
        <Route path="/user/forget" element={<ForgetPage />} />

        {/*AUTH NEED Start*/}
        <Route path="/" element={<Navigate to="/main/myteams" replace />} />

        <Route path="/main/:id" element={
          <RequireAuth>
            <Main />
          </RequireAuth>
        } />



        <Route path="/main/myteams" element={
          <RequireAuth>
            <Teams />
          </RequireAuth>
        } />



        <Route path="/main/profile" element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        } />

        <Route path="/main/integrations" element={
          <RequireAuth>
            <Integrations />
          </RequireAuth>
        } />

        <Route path="/main/mrs" element={
          <RequireAuth>
            <Mrs />
          </RequireAuth>
        } />



        {/* <Route path="/user" element={<Navigate to="/user/profile" replace />} />
        <Route path="/user/profile" element={
          <RequireAuth>
            <UserProfilePage />
          </RequireAuth>
        } />

        <Route path="/user/password" element={
          <RequireAuth>
            <UserPasswordPage />
          </RequireAuth>
        } /> */}


        <Route path="*" element={<NotFound />} />

      </Routes>
    </>
  )
}
export default App;
