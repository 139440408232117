import { AUTH_LOGIN, AUTH_LOGOUT } from '../types/types'

const initialState = {
  token: null,
  userId: null,
  user: null,
  isAuthenticated: false,
}

export const authReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case AUTH_LOGIN:
      const data = {...initialState, ...action.payload, isAuthenticated: true}
      return { ...state,  ...data}
    case AUTH_LOGOUT:
      return { ...state,  ...initialState}
    default:
      return state
  }
}